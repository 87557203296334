// Makes reset all link perform a blank search
export async function setUpResetLink() {
 const formEl = document.querySelector('.yxt-SearchBar-form');
 const inputEl = document.querySelector('.js-yext-query');
 const resetLink = document.querySelector('.js-yxt-FilterBox-reset');

 if (formEl && inputEl && resetLink && !(resetLink.classList.contains('is-initialized'))) {
   resetLink.classList.add('is-initialized');
   resetLink.addEventListener('click', () => {
     inputEl.value = '';
     formEl.dispatchEvent(new Event('submit'));
   });
 }
}
